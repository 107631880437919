/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/* Custom font */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
	outline: none // removes google's black box highlight on focus of clickable elements
}

html {
	--ion-font-family: "Roboto", sans-serif;
}

:root ion-app {
	// handles padding for devices with notches (a.k.a. "dynamic islands")
	left: env(safe-area-inset-left, 0);
	top: env(safe-area-inset-top, 0);
	right: env(safe-area-inset-right, 0);
	bottom: env(safe-area-inset-bottom, 0);
}

:root {
	--app-spacing: 16px; // common margin/padding

	--color-primary-1: #368C82;
	--color-primary-2: #D8E8E5;
	--color-primary-3: #ECF6F4;
	--color-primary-4: #F5FBFA;
	--color-secondary-1: #999999;
	--color-secondary-2: #EBEBEB;
	--color-secondary-3: #F8F8F8;
	--color-critical-1: #F04141;
	--color-critical-2: #F1DCDC;
	--color-warning-1: #FFCE00;
	--color-success-1: #10DC60;
	--color-success-2: #C6FCD2;
	--color-other-1: #3880FF;
	--color-other-2: #CE07E6;
	--color-other-3: #FDFBE8;
	--color-service-1: #C9D3E0;

	--hover-filter: brightness(0.95);

	--keyline-border: 1px solid var(--color-secondary-2);
	--keyline-border-alt: 1px solid var(--color-secondary-3);

	--item-horizontal-spacing: 12px;
	--item-vertical-spacing: 10px;
	--item-min-height: 40px;
	--placeholder-opacity: 0.6;
	--placeholder-font-style: italic;

	--heading-large-font-size: 1.125rem;

	--box-border-radius: 3px;

	--multi-line-line-height: 1.2;
	--popover-item-padding: 6px; // because inline popovers render inside ng-template, they don't inherit properties from the component
	
	--validate-display: inline-block;
	--validate-size: 15px;
	--validate-background-url: url('/svg/cris-invalid.svg');
	--validate-background-size: 100% 100%;
	--validate-content: '';
	--validate-spacing: 5px;

	--form-group-gap: 5px;

	--button-spacing: 10px;
}

.ion-page {
	justify-content: flex-start; // override Ionic's default setting (space-between)
	background-color: #fff // without this, the previous page is briefly visible when navigating to a new page
}

.page-content {
	flex: 1 0 0;
	display: flex;
	flex-direction: column
}

.no-select {
	user-select: none
}

.no-events {
	pointer-events: none // use this to disable pointer events on elements whose parents need control position of popovers
}

/* button container styling - for right aligning buttons with option to left align some, e.g. cancel */
.button-container {
	display: flex;
	justify-content: end;
	column-gap: var(--button-spacing)
}

.button-container .left-aligned { // note: only one button needs this class. It, and all buttons before it, will be left aligned
	display: inline-block;
	margin-right: auto
}

/* end of button container styling */

/* modal styling */
ion-modal.task-document-modal {
	--min-height: 80vh
}

@media only screen and (min-width: 768px) and (min-height: 600px) { // note: this matches ionic's breakpoints
	ion-modal.modal-fit-content { // note: any internal scrollable flex items (e.g. using scroll-vertical class inside ng-component) may need max-height of less than 80vh
		--height: auto;
		--max-height: 80vh
	}
}

// used for modals that are stacked on top of each other when the size of modals underneath have been customised (https://ionicframework.com/docs/v7/api/modal#styling)
ion-modal.stacked-modal {
	--box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
	--backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important
}

.modal-buttons {
	flex: 0 0 auto;
	padding: var(--app-spacing)
}

/* end of modal styling */

/* scrollbar styling and display */
.scroll-vertical,
.scroll-horizontal,
.scroll-both,
ion-popover::part(content) {
	// scrollbar styling properties
	--scrollbar-width: 8px;
	--scrollbar-track-colour: transparent;
	--scrollbar-thumb-colour: var(--color-secondary-2);
	--scrollbar-thumb-hover-colour: var(--color-secondary-2);
	--hover-background-colour-number: 126;
	--hover-background-colour-opacity: 0.2;
	--hover-background-colour-rgb: rgb(var(--hover-background-colour-number) var(--hover-background-colour-number) var(--hover-background-colour-number));
	--hover-background-colour-rgba: rgb(var(--hover-background-colour-number) var(--hover-background-colour-number) var(--hover-background-colour-number) / var(--hover-background-colour-opacity));

	// scrollbar styling using the current latest W3C CSS draft recomendation (currently supported by Mozilla Firefox)
	scrollbar-width: thin;
	scrollbar-color: var(--scrollbar-thumb-colour) var(--scrollbar-track-colour);

	// scrollbar styling using deprecated WebKit CSS properties (currently supported by Google Chrome, Microsoft Edge, and Apple Safari)
	&::-webkit-scrollbar {
		width: var(--scrollbar-width);
		height: var(--scrollbar-width)
	}

	&::-webkit-scrollbar-track {
		background-color: var(--scrollbar-track-colour)
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--scrollbar-thumb-colour)
	}

	&::-webkit-scrollbar-thumb:hover {
		background-color: var(--scrollbar-thumb-hover-colour)
	}
}

.scroll-vertical,
ion-popover::part(content) {
	overflow-y: auto
}

.scroll-horizontal {
	overflow-x: auto
}

.scroll-both {
	overflow: auto
}

/* end of scrollbar styling and display */

/* stacked-grid styling */

.stacked-grid {
	height: 100%; // fill the container height
	display: grid; // uses default grid container settings, meaning the items define the grid. E.g. grid-template is "none", gap is 0, etc
	grid-template-rows: minmax(0, 1fr); // works like flex: 1 0 0 (allows the grid to ensure the height of the row fills the available space, ignoring the content size)
	overflow: hidden // stop overflow. Child elements should handle this, e.g. shrink or add scrollbars
}

.stacked-grid-item-root {
	grid-area: 1 / 1 / 2 / 2 // grid-row-start grid-column-start grid-row-end grid-column-end. I.e. each item spans the whole grid. By default, items stretch to fill their area
}

.stacked-grid-item-overlay {
	@extend .stacked-grid-item-root;
	z-index: 1;
	background-color: #fff
}

/* end of stacked-grid styling */

.rounded-border-box {
	border: 1px solid var(--color-secondary-2);
	border-radius: var(--box-border-radius);
	background-color: #fff
}

.highlighted-box {
	background-color: var(--color-other-3)
}

.margin-top {
	margin-top: var(--app-spacing);
}

.app-margin-horizontal {
	margin-left: var(--app-spacing);
	margin-right: var(--app-spacing)
}

.half-margin-top {
	margin-top: calc(var(--app-spacing) / 2);
}

.app-padding {
	padding: var(--app-spacing);
}

.app-padding-horizontal {
	padding-left: var(--app-spacing);
	padding-right: var(--app-spacing)
}

.half-padding-top {
	padding-top: calc(var(--app-spacing) / 2);
}

// common classes
.footer-box {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12)
}

.app-search {
	padding: var(--app-spacing);
	--border-radius: var(--box-border-radius);
	--box-shadow: 0 0 0 1px var(--color-secondary-2);
	--icon-color: var(--color-primary-1);
	--clear-button-color: var(--color-primary-1);
}

.navigable-element:hover,
.hoverable-element:hover {
	cursor: pointer;
	filter: var(--hover-filter);
	backdrop-filter: var(--hover-filter);
	-webkit-backdrop-filter: var(--hover-filter);
}

.disabled-label {
	color: var(--color-secondary-1);
}

/* form classes */

.form-section {
	display: flex;
	flex-direction: column;
	gap: 15px
}

.form-group {
	display: flex;
	flex-direction: column;
	gap: var(--form-group-gap)
}

.form-control-label {
	margin: 15px 0 10px
}

/* end of form classes */

// reactive form control validation

.validation-section-label, .validation-label, .validation-group-label {
	display: flex;
	align-items: center
}

.validatable-section.ng-invalid .validation-section-label:before,
.validatable-group.ng-invalid .validatable-group-control .validation-label:before,
.validatable-group.ng-invalid .validation-group-label:before,
.validatable-control.ng-invalid .validation-label:before,
.app-invalid .validation-label:before {
	flex: 0 0 auto;
    display: var(--validate-display);
    width: var(--validate-size);
    height: var(--validate-size);
    background-image: var(--validate-background-url);
    background-size: var(--validate-background-size);
    content: var(--validate-content);
    margin-right: var(--validate-spacing)
}

// navigable elements need ionic ripple effect. The ion-activable class is required for ionic ripple effect to work, and <ion-ripple-effect></ion-ripple-effect> is also required inside the element. See Ionic documentation for details
.navigable-element.ion-activatable {
	position: relative; // required for ionic ripple effect
	overflow: hidden; // recommended for ionic ripple effect
}

// header styles
.menu-bar .header-md {
	clip-path: inset(0 0 -10px 0); // stops horizontal bleeding of box shadow (otherwise vertical shadow appears between menu-bar header and page-container header)
}

// popover menu styling
.popover-menu {
	font-size: 0.9375rem
}

.popover-menu ion-popover::part(content) {
	min-width: 210px
}

.popover-menu-content {
	// a div of this class is needed for the border separators of app-item elements to work. Without it,
	// the last element can be a zero height div that's inserted by ionic at runtime, breaking the :not(:last-child) style
	display: block
}

.popover-menu app-heading {
	--custom-padding: 10px;
	--custom-font-size: 0.9375rem;
	--custom-font-weight: 500
}

.popover-menu app-item {
	padding: 10px
}

.popover-menu app-item:not(:last-child) {
	border-bottom: var(--keyline-border)
}

.popover-menu.popover-alt app-item {
	background-color: var(--color-secondary-2)
}

.popover-menu.popover-alt app-item:not(:last-child) {
	border-bottom: var(--keyline-border-alt)
}

// ionic overrides
ion-segment {
	grid-auto-columns: 1fr;
	margin-bottom: calc(var(--app-spacing) / 2)
}

ion-segment-button {
	max-width: none;
	text-transform: none;
	font-size: 1rem
}

// ionic alert customisation
.alert-button.button-critical {
	color: var(--color-critical-1)
}

// resus code related styles

.resus-black-heart { --color: #000 !important }
.resus-red-heart { --color: var(--color-critical-1) !important }
.resus-black-text { color: #000 !important }
.resus-red-text { color: var(--color-critical-1) !important }
.resus-red-fill { --color: var(--color-critical-1) !important }
.resus-black-fill { --color: #000 !important }

// end of resus code related styles